.failed {
  width: 100px;
  height: 80px;
  margin: 0 auto;
}
.failed .cross__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #EF4836;
  fill: none;
  -webkit-animation: stroke-cross 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
          animation: stroke-cross 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}
.failed .cross {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 10% auto;
  box-shadow: inset 0px 0px 0px #7ac142;
  -webkit-animation: fill-cross 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
          animation: fill-cross 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
}
.failed .cross__check {
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  -webkit-animation: stroke-cross 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
          animation: stroke-cross 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}
@-webkit-keyframes stroke-cross {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes stroke-cross {
  100% {
    stroke-dashoffset: 0;
  }
}
@-webkit-keyframes scale-cross {
  0%, 100% {
    -webkit-transform: none;
            transform: none;
  }
  50% {
    -webkit-transform: scale3d(1.1, 1.1, 1);
            transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes scale-cross {
  0%, 100% {
    -webkit-transform: none;
            transform: none;
  }
  50% {
    -webkit-transform: scale3d(1.1, 1.1, 1);
            transform: scale3d(1.1, 1.1, 1);
  }
}
@-webkit-keyframes fill-cross {
  100% {
    box-shadow: inset 0px 0px 0px 30px #EF4836;
  }
}
@keyframes fill-cross {
  100% {
    box-shadow: inset 0px 0px 0px 30px #EF4836;
  }
}
