//
// Filter menu on desktop
//

.filter {
  height: @navbar-height;
  line-height: @navbar-height;
  a {
    .fa {
      margin-right: 5px;
      color: #999;
    }
    margin-right: 20px;
    color: @filter-link-color;
    &:last-child {
      margin-right: 0px;
    }
    &.active {
      color: @filter-link-color-active;
    }
  }
  &.fixed {
    position: fixed;
    top: 0px;
    width: 100%;
    background-color: #fff;
    z-index: 100;
  }
}

.filter {
  font-size: 13px;
  font-weight: 400;
  transition: all 200ms ease-in-out;
  border-bottom: 1px solid @border;
  position: fixed;
  top: @navbar-height;
  z-index: 10;
  width: 100%;
  background: @filter-bg;
}


//
// Filter menu on mobile
//

#menu {
  a {
    display: block;
    width: 100%;
    padding: 10px 10px 10px 20px;
    border-bottom: 1px solid @border;

    .fa {
      display: inline-block;
      width: 20px;
      margin-right: 5px;
      color: #999;
    }

    &.active {
      background-color: #000;
      color: #fff;
    }
  }
}
