.filter .colors-indent {
  padding: 10px 5px 10px 15px;
}

.filter a.link-color {
  border-radius: 50%;
  border: 1px solid @border;
  width: 20px;
  height: 20px;
  display: inline-block;
  margin-right: 10px;
  margin-top: 15px;
  outline: 0px;
  &:last-child {
    margin-right: 0px;
  }
}

.filter a.link-color.link-light {
  background: @bg-light;
}
.filter a.link-color.link-dark {
  background: @bg-dark;
}
