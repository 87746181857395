html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  font-weight: normal;
  font-size: 14px;
  line-height: 1.4;
  padding-top: 50px;
}

//
// Typography
//

h1, h2, h3, h4, h5 {
  margin-top: 0px;
}

h1 {
  font-weight: 300;
  font-size: 200%;
  padding-bottom: .5em;
  margin-bottom: .5em;
  border-bottom: 1px solid #eee;
}

pre {
  white-space: pre-wrap;
  tab-size: 2;
}

.pretty-list {
  font-size: 1.2em;
  font-weight: 300;
  line-height: 2em;
}

//
// file list
//
.file-item {
  padding: 10px 0;
  .fa {
    width: 20px;
  }
}
.file-name {
  font-weight: bold;
}
.file-desc {
  font-size: 12px;
  line-height: 1.8;
}

.banner-page {
  display: flex;
}

// Users
.users {
  float: right;
  a {
    margin-right: 20px;
    &:last-child {
      margin-right: 0px;
    }
  }
}


.clipboard {
  cursor: pointer;
}

.container {
  width: 96%;
  margin: 0 auto;
}

.relative {
  position: relative;
}


//
// Font awesome list
//

.fa-ul {
  margin-top: 10px;

  li {
    line-height: 1.5;
  }

  &.fa-ul-meta {
    font-size: 12px;
    line-height: 18px;
  }
}

//
// Tabs
//

.tab {
  display: none;
  margin-bottom: 40px;
}

.all {
  .main .flex-layout {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    .tab {
      padding-right: 20px;
    }
  }
}

// Banner page
.page-space {
  padding-top: 30px;
}
.page-banners {
  padding-top: 100px;
}

//
// Dropzone
//

.dropzone-region {
  border: 1px solid #eee;
  padding: 20px;
  margin-bottom: 20px;
  transition: height 300ms ease-in-out;
  &:hover {
    transition: height 300ms ease-in-out;
  }
}


// Form box
.box {
  border: 1px solid @border;
  padding: 30px;
}

.help-block {
  font-size: 12px;
}

// List
ul.list {
  padding: 0px;
}

ul.list,
ul.list li {
  list-style: none;
}

// space
.md-right {margin-right: 10px;}
.md-top {margin-top: 10px;}
.md-bottom {margin-bottom: 10px;}

.sm-bottom {margin-bottom: 0px;}

//
//
//
.video-container {
  position: relative;
}

.video-overlay {
  background: transparent url('../images/play.png') center center no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
}
