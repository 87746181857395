.search-box {
  margin: 10px 0 0px 0;
  padding: 10px 0;
}

.search-box input {
  width: 100%;
  padding: 10px 15px;
  border: 1px solid @border;
  outline: none;
}
