//
// Large facebook
//

.facebook-post {
  width: 100%;
  max-width: 470px;
  background: #fff;
  box-shadow: 0 0 0 1.5px rgba(0, 0, 0, .1) inset, 0 1px 1px rgba(0, 0, 0, .05);
  margin-bottom: 40px;

  img {
    display: block;
    width: auto;
    max-width: 100%;
    height: auto;
  }

  .facebook-image {
    max-width: 470px;
  }
  .facebook-content {
    margin: 10px 12px;
    padding-bottom: 10px;

    .facebook-title {
      font-family: Georgia, 'lucida grande', tahoma, verdana, arial, sans-serif;
      font-size: 18px;
      font-weight: 500;
      line-height: 22px;
      margin-bottom: 5px;
      max-height: 110px;
      overflow: hidden;
      word-wrap: break-word;
      color: #141823;
    }
    .facebook-description {
      font-family: helvetica, arial, sans-serif;
      font-size: 12px;
      line-height: 16px;
      max-height: 80px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
    }

    .facebook-sitename {
      padding-top: 9px;
      font-size: 11px;
      line-height: 11px;
      text-transform: uppercase;
      color: #9197a3;
      margin: 5px 0;
    }
  }
}

//
// Small preview
//

.facebook-post-small {
  width: 100%;
  max-width: 320px;
  background: #fff;
  box-shadow: 0 0 0 1.5px rgba(0, 0, 0, .1) inset, 0 1px 1px rgba(0, 0, 0, .05);
  margin-bottom: 40px;
  border-radius: 4px 4px 0 0;

  img {
    border-radius: 4px 4px 0 0;
    display: block;
    width: auto;
    max-width: 100%;
    height: auto;
  }

  .facebook-image {
    max-width: 470px;
  }
  .facebook-content {
    margin: 10px 12px;
    padding-bottom: 10px;

    .facebook-title {
      font-family: helvetica, arial, sans-serif;
      font-size: 14px;
      font-weight: bold;
      line-height: 18px;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      max-height: 36px;
      overflow: hidden;
      position: relative;
      text-overflow: ellipsis;
      white-space: normal;
    }
    .facebook-sitename {
      font-size: 12px;
      line-height: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #9197a3;
      margin-top: 3px;
    }
  }
}

//
// Facebook Video Large
//

.facebook-video {
  font-family: Helvetica, 'HelveticaNeue', Arial, sans-serif;
  width: 100%;
  //max-width: 450px;
  background: #fff;
  box-shadow: 0 0 0 1.5px rgba(0, 0, 0, .1) inset, 0 1px 1px rgba(0, 0, 0, .05);
  margin-bottom: 40px;
  padding: 12px;

  video {
    width: 100%;
    height: auto;
  }

  .video-overlay {
    background-size: 72px 72px;
  }

  .facebook-title {
    font-size: 14px;
    font-weight: bold;
    color: #365899;
  }

  .facebook-grey {
    font-size: 12px;
    color: #90949c;
  }

  .facebook-inline-status {
    font-size: 12px;
    color: #90949c;
    span {
      margin-right: 8px;
    }
  }

  .facebook-inline-actions {
    border-top: 1px solid #e5e5e5;
    color: #7f7f7f;
    font-size: 12px;
    font-weight: bold;
    margin-top: 10px;
    padding-top: 10px;
    span {
      margin-right: 16px;
    }
  }

}

.facebook-video-small {
  font-family: Helvetica, 'HelveticaNeue', Arial, sans-serif;
  width: 100%;
  max-width: 274px;
  background: #fff;
  box-shadow: 0 0 0 1.5px rgba(0, 0, 0, .1) inset, 0 1px 1px rgba(0, 0, 0, .05);
  margin-bottom: 40px;
  padding: 12px;

  video {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
  }

  .facebook-title {
    font-size: 12px;
    font-weight: bold;
    color: #365899;
  }

  .facebook-grey {
    font-size: 12px;
    color: #90949c;
  }

  .video-overlay {
    background-size: 52px 52px;
  }

}

.btn-grey {
  background-color: #f6f7f9;
  border: 1px solid #ced0d4;
  color: #4b4f56;
  height: 24px;
  line-height: 24px;
  font-size: 12px;
  border-radius: 2px;
  box-sizing: content-box;
  font-family: helvetica, arial, sans-serif;
  font-size: 12px;
  -webkit-font-smoothing: antialiased;
  font-weight: bold;
  padding: 4px 10px;
  position: relative;
  text-align: center;
  text-shadow: none;
  vertical-align: middle;
  cursor: pointer;
}
