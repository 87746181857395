.card {
  height: 200px;
  margin-bottom: 30px;
}

.card-background {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  height: 100%;
  min-height: 200px;
}

.card-overlay {
  position: absolute;
  top: 0px;
  left: 0px;

  display: block;
  width: 100%;
  height: 100%;
  min-height: 200px;
  border-radius: 8px;
  padding: 20px;

  background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(0,0,0,0.8) 100%);
  background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(0,0,0,0.8) 100%);
  background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(0,0,0,0.8) 100%);
}

.card .card-inner {
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.card .client {
  font-size: 16px;
  font-weight: normal;
  color: #fff;
  word-wrap: break-word;
  word-break: break-all;
}

.card .file-size,
.card .file-changed {
  font-weight: normal;
  font-size: 12px;
  color: #999;
}

.card:hover .card-overlay {
  opacity: 1;
  transition: all .2s;
}
.card:hover .card-trash {
  display: block;
  opacity: 1;
  transition: all .2s;
}

.card-trash {
  display: none;
  opacity: 0;
  position: absolute;
  top: 15px;
  right: 30px;
  z-index: 10;
  color: #fff;
  font-size: 1em;
  padding: 10px;
}
