.page-banners.bg-light {
  transition: all 200ms ease-in-out;
  background: @bg-light;
}

.header {
  transition: all 200ms ease-in-out;
  border-bottom: 1px solid #E5E8ED;
}

.fa-ul-meta .clipboard {
  display: inline-block;
}

.fa-ul {
  color: #354052;
}

.fa-ul-meta .clipboard,
.fa-ul-meta a {
  transition: all 150ms;
  color: #33B0F7;
  &:hover {
    transition: all 150ms;
    color: #354052;
  }
}

.file-item {
  border-bottom: 1px solid #eee;
  .file-desc {
    color: #999;
  }
}


.page-banners.bg-dark {
  transition: all 200ms ease-in-out;
  background: @bg-dark;
  color: #CCC;

  .fa-ul {
    color: #999;
  }

  .fa-ul-meta .clipboard,
  .fa-ul-meta a {
    transition: all 150ms;
    color: #999;
    &:hover {
      transition: all 150ms;
      color: #fff;
    }
  }

}
